import * as React from "react";
import { useStyles } from "./InfrastructureManagement.styles";
import { Grid, Typography } from "@material-ui/core";
import HeadingDash from "../../../../common/components/HeadingDash/HeadingDash";
import IconCard from "../../../../common/components/IconCard/IconCard";
import { IconData } from "../../../../common/icons/IconData";
import { IconBattery } from "../../../../common/icons/IconBattery";
import { IconMobile } from "../../../../common/icons/IconMobile";
import { IconPerformance } from "../../../../common/icons/IconPerformance";

const EVoInfrastructureManagement = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.futureProofSection}>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} lg={6}>
          <Typography variant="h2">
            <HeadingDash justify="flex-start" />
            EV infrastructure management in one place
          </Typography>
          <ul>
            <li>Connect all charge points with EV charging networks</li>
            <li>
              Integrate with your internal systems and third-party services
            </li>
            <li>V2G, PV, energy storage and optimization features</li>
          </ul>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid container item xs={6} md={3}>
              <IconCard
                icon={<IconBattery />}
                text="Charging points management"
              />
            </Grid>
            <Grid container item xs={6} md={3}>
              <IconCard
                icon={<IconMobile />}
                text="Customer & payments management"
              />
            </Grid>
            <Grid container item xs={6} md={3}>
              <IconCard icon={<IconData />} text="Advanced dashboards" />
            </Grid>
            <Grid container item xs={6} md={3}>
              <IconCard
                icon={<IconPerformance />}
                text="Optimized EV charging"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};
export default EVoInfrastructureManagement;
