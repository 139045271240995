import React from "react";
import EVoInfrastructureManagement from "../Sections/EVoInfrastructureManagement/EVoInfrastructureManagement";
import StartingSection from "../../../common/sections/StartingSection/StartingSection";
import SummarySection from "../../../common/sections/SummarySection/SummarySection";
import ChooseServiceSection from "../../../common/sections/ChooseServiceSection/ChooseServiceSection";
import OurEcosystemSection from "../../../common/sections/OurEcosystemSection/OurEcosystemSection";
import { EVoEpIllustration } from "../../../common/images/illustrations/EVoEpIllustration";
import { EVoAllFlatIcon } from "../../../common/images/EVoAllFlatIcon";
import AllFeaturesSection from "../../../common/sections/AllFeaturesSection/AllFeaturesSection";
import { EVoEcosystem } from "../../../common/images/EVoEcosystem";
import { EmailInput } from "../../../common/Layout/Layout";
import { EpServicesEnum } from "../../../api/useEmailService";

const ChooseServiceSectionFeatures = [
  "Scale your business with all-in-one platform",
  "Future-proof load management systems",
];

const EVoFeatures = [
  "Charging infrastructure Operations & Management",
  "Smart Energy Management",
  "EV Drivers Mobile and web apps",
  "Monitoring & Analytics",
  "Payment and billing",
  "Roaming, OICP / OCPI / Custom",
];

interface EVoEpProps {
  emailInputRef: EmailInput;
}

const EVoEnergyPlatform = ({ emailInputRef }: EVoEpProps): JSX.Element => {
  return (
    <>
      <StartingSection
        service={EpServicesEnum.EVo}
        emailInputRef={emailInputRef}
        title="EVoEP"
        subtitle="All-in-one solution to ensure more efficient management of your EV charging infrastructure"
        description="Our platform is created to help you with charging infrastructure management, customer and partner relationships, payments, billing, and more."
        illustration={<EVoEpIllustration />}
      />
      <SummarySection>
        <>
          {" "}
          <b> EVoEP </b> is created to help you with
          <span> charging infrastructure management</span>,
          <br /> customer and partner relationships, payments, billing, and
          more.
        </>
      </SummarySection>
      <EVoInfrastructureManagement />
      <ChooseServiceSection
        title="Why choose EVoEP Platform?"
        description="EV charging networks and energy management - all in one place. With our platform, you can connect all charge points with EV charging networks, manage drivers, create bespoke billing packages and optimize energy assets"
        displayMLInfo={false}
        features={ChooseServiceSectionFeatures}
      />
      <OurEcosystemSection
        header="Our ecosystem"
        description="Something should be added here as text"
        picture={<EVoEcosystem />}
      />
      <AllFeaturesSection
        heading="All features in one place"
        description="Something should be added here as text"
        icon={<EVoAllFlatIcon />}
        features={EVoFeatures}
      />{" "}
    </>
  );
};

export default EVoEnergyPlatform;
